.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Use the full height of the viewport */
    position: relative;
    text-align: center;
    background-position: center center;
    /* Other styles */
  }
  
  .header-text {
    font-size: 3rem;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px; /* Add some space between the text and the form */
    /* Remove margin-top if you want to vertically center on the page */
    z-index: 10;
  }
  
  /* If you have a specific class for the form or card, you might want to add: */
  .form-container { /* Replace with your form's class name if it's different */
    width: 300px; /* Or whatever width you prefer */
    /* Center the form container if it's not already centered by text-align */
    margin: 0 auto;
  }

  .tagline-text {
    font-size: 1.5rem; /* Adjust the size as needed */
    color: #fff; /* Choose a text color that stands out against your background */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: Adds a shadow to the text for better readability */
    margin-bottom: 2rem; /* Space between tagline and form */
    z-index: 10; /* Ensure it's above other elements */
  }
  
  .ant-card-body {
    padding: 15px !important;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  :where(.css-dev-only-do-not-override-2q8sxy).ant-btn {
    padding-top: 4px !important;
    padding-bottom: 4px !important;

  }

  .success-message {
    color: #28a745;  /* A green color for success messages */
    font-size: 1rem;
    margin: 20px 0;
  }

  /* Style for mobile screen */
@media (max-width: 600px) {
    .mobile-card {
      width: 50%;
    }
  }

@media (max-width: 475px) {
    .tagline-text-475 {
        font-size: 1rem; /* Adjust the size as needed */
        color: #fff; /* Choose a text color that stands out against your background */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: Adds a shadow to the text for better readability */
        margin-bottom: 2rem; /* Space between tagline and form */
        z-index: 10; /* Ensure it's above other elements */
      }
      .header-text-475 {
        font-size: 2rem;
        color: #fff;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        margin-bottom: 20px; /* Add some space between the text and the form */
        /* Remove margin-top if you want to vertically center on the page */
        z-index: 10;
      }
  }