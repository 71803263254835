.App {
  text-align: center;
}

.landing-page {
  height: 100vh; /* Full height */
  background-size: cover; /* Cover the entire screen */
  display: flex;
  justify-content: center;
  align-items: center;
}
